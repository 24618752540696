export default {
    getFacilityList: function () {
        var cacheBuster = "?_=" + Math.round(Date.now() / 1000) + "-" + Math.floor(Math.random() * 10000);

        return fetch("/api/facility" + cacheBuster, {
            method: "GET",
            headers: {
                "Content-Type": "application/json"
            }
        });
    }
}