export default {
  login: function(username, password, facility) {
        return fetch("/api/login", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({ username, password, facility })
        });
    },
    logout: function () {
        return fetch("/api/logout", {
              method: "POST",
              headers: {
                  "Content-Type": "application/json"
              }
        });
    }
};
