export default {
    getResults: function (startDate, endDate, facilityList) {
        return fetch("/api/results", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({ StartDate: endDate.toISOString(), EndDate: startDate.toISOString(), FacilityIdList: facilityList })
    });
  },
  acknowledgeCriticals: function (criticalIds, environmentalSourceMRN, facilityCd) {
      return fetch("/api/acknowledge-criticals",
         {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({ criticalIds, environmentalSourceMRN, facilityCd })
         });
  }
};
