export default {
    namespaced: true,
    state: {
        loading: false,
        currentEndDate: null,
        loadedData: [],
        onlyCriticals: true,
        reloadNeeded: false
    },
    mutations: {
        saveLoadedData(state, { endDate, data }) {
            
            state.currentEndDate = endDate;

            if (data !== null) {
                state.loadedData.push(...data);
            }
        },
        setLoading(state, { loading }) {
            state.loading = loading;
        },
        setOnlyCriticals(state, { onlyCriticals }) {
            state.onlyCriticals = onlyCriticals;
        },
        setReloadNeeded(state, { reloadNeeded }) {
            state.currentEndDate = null;
            state.loadedData = [];
            state.reloadNeeded = reloadNeeded;
        },
        clearData(state) {
            state.currentEndDate = null;
            state.loadedData = [];
            state.reloadNeeded = true;
        }
    },
    actions: {

    }
};