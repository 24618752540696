
export default {
    namespaced: true,
    state: {
        selectedFacilityIds:[],
        facilityList: []
    },
    mutations: {
        clearData(state) {
            state.facilityList = [];
            state.selectedFacilityIds = [];
        },
        setSelectedFacilityIdList(state, { facilityIdList }) {
            state.selectedFacilityIds = facilityIdList;
        },
        setFacilityList: function (state, { facilityList, facilityCdLogin }) {
            state.selectedFacilityIds = [];

            for (var i = 0; i < facilityList.length; i++) {
                if (facilityList[i].facilityCd === facilityCdLogin) {
                    state.selectedFacilityIds.push(facilityList[i].facilityId);

                    facilityList[i].showFacility = true;
                } else {
                    facilityList[i].showFacility = false;
                }
            }

            
            state.facilityList = facilityList;
        }
    }
}