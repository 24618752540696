<template>
    <div>
        <v-card class="mx-auto"
                max-width="344">
            <v-card-text>
                <v-text-field label="Username" hide-details="auto" v-model="username"></v-text-field>
                <v-text-field label="Password"
                              hide-details="auto"
                              v-model="password"
                              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                              :type="showPassword ? 'text' : 'password'"
                              name="input-10-1"
                              hint="At least 8 characters"
                              @click:append="showPassword = !showPassword"></v-text-field>
                <v-text-field label="Facility Code" hide-details="auto" v-model="facility"></v-text-field>
                <v-spacer></v-spacer>
                <v-alert v-show="showAlert" type="error" style="padding-top: 12px 0 0 0">{{ alert }}</v-alert>
            </v-card-text>
            <v-card-actions>                
                    <v-btn v-on:click.native="login" color="primary" style="width:100%">{{ loginButtonText }}</v-btn>                
            </v-card-actions>
        </v-card>
    </div>
</template>

<script>
    import loginService from "../services/loginService.js";
    import facilityService from "../services/facilityService.js";

    export default {
        name: "Login",
        data: function () {
            return {
                loginButtonText: "Log In",
                username: "",
                password: "",
                facility: "",
                showPassword: false,
                alert: "",
                showAlert: false
            };
        },
        methods: {
            login: function () {

                this.facility = this.facility.toUpperCase();
                var currentThis = this;

                this.loginButtonText = "Authenticating ...";

                loginService
                    .login(this.username, this.password, this.facility)
                    .then(response => response.json())
                    .then(data => {
                        if (data.result === true) {
                            this.$store.commit('logIn', { canAcknowledgeCriticals: data.canAcknowledgeCriticals });

                            facilityService.getFacilityList().
                                then(response => response.json()).
                                then(data => {
                                    currentThis.$store.commit('facilityStore/setFacilityList', { facilityList: data, facilityCdLogin: currentThis.facility });

                                    currentThis.$router.push("/results");
                            });

                            
                        } else {
                            this.loginButtonText = "Log In";
                            this.alert = "Username/password does not match.";
                        }
                    })
                    .catch(() => {
                        this.loginButtonText = "Log In";
                        this.alert = "Error contacting server.";
                        this.showAlert = true;
                    });               
                
            }
        },
        watch: {
            username: function () {
                this.showAlert = false;
            },
            password: function () {
                this.showAlert = false;
            },
            facility: function () {
                this.showAlert = false;
            }
        },
        mounted: function () {
            var store = this.$store;
            if (this.$store.state.authenticated) {
                store.commit('resultStore/clearData');
                store.commit('facilityStore/clearData');
                loginService.logout().then(() => store.commit('logOut')).then(location.reload()).catch(() => store.commit('logout'));                
            }
        }
    };
</script>

