import Vue from "vue";
import VueRouter from "vue-router";
import Login from "../views/Login.vue";
import Results from "../views/Results.vue";
import store from "@/store/index.js"
const aboutView = () => import('@/views/About');
const contactView = () => import('@/views/Contact');

Vue.use(VueRouter);

const routes = [
    {
        path: "/login",
        name: "Login",
        component: Login
    },
    {
        path: "/results",
        name: "Results",
        component: Results,
        beforeEnter: (to, from, next) => {
            if (store.state.authenticated) {
                next();
            } else {
                next('/login');
            }
        }
    },
    {
        path: "/about",
        name:"About",
        component: aboutView
    },
    {
        path: "/contact",
        name: "Contact",
        component: contactView
    },
    {
        path: "*",
        redirect:"/login"
    }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
