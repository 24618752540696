<template>
    <v-list>
        <v-list-item>
            <template>
                <v-list-item-action>
                    <v-switch v-model="onlyCriticals"></v-switch>
                </v-list-item-action>

                <v-list-item-content>
                    <v-list-item-title>Criticals Only</v-list-item-title>
                </v-list-item-content>
            </template>
        </v-list-item>
        <v-list-group value="true">
            <template v-slot:activator>
                <v-list-item-title>Facilities</v-list-item-title>
            </template>
            <v-list-item v-for="(facility, i) in facilityList" :key="i" two-line>
                <template>
                    <v-list-item-action>
                        <v-switch v-model="facility.showFacility"></v-switch>
                    </v-list-item-action>

                    <v-list-item-content>
                        <v-list-item-title>{{ facility.facilityCd }}</v-list-item-title>
                        <v-list-item-subtitle>{{ facility.faciltyName }}</v-list-item-subtitle>
                    </v-list-item-content>
                </template>
            </v-list-item>
        </v-list-group>
    </v-list>
</template>
<script>
    export default {
        name: 'results-settings',
        props: {
            visible: Boolean
        },
        data: function () {
            var selectedList = [];

            var facilityList = this.$store.state.facilityStore.facilityList;

            for (var i = 0; i < facilityList.length; i++) {
                selectedList[i] = facilityList[i].showFacility;
            }

            return { selectedList };
        },
        computed: {
            onlyCriticals: {
                get: function () {
                    return this.$store.state.resultStore.onlyCriticals;
                },
                set: function (onlyCriticals) {                    
                    this.$store.commit('resultStore/setOnlyCriticals', { onlyCriticals });
                }
            },
            showAllFacilities: {
                get: function () {
                    return this.$store.state.resultStore.showAllFacilities;
                },
                set: function (showAllFacilities) {
                    this.$store.commit('resultStore/setShowAllFacilities', { showAllFacilities });
                }
            },
            facilityList: {
                get: function () {
                    return this.$store.state.facilityStore.facilityList;
                }
            }
        },
        watch: {
            visible: function(newVal) {
                var facilityList = this.$store.state.facilityStore.facilityList;
                var i = 0;

                if (newVal === true) {
                    var selectedList = [];

                    for (i = 0; i < facilityList.length; i++) {
                        selectedList[i] = facilityList[i].showFacility;
                    }
                    this.selectedList = selectedList;
                }
                else if (newVal === false) {
                    var selectedFacilityIds = [];
                    var reloadNeeded = false;

                    for (i = 0; i < facilityList.length; i++) {
                        if (facilityList[i].showFacility === true) {
                            selectedFacilityIds.push(facilityList[i].facilityId);
                        }
                        if (this.selectedList[i] !== facilityList[i].showFacility) {
                            reloadNeeded = true;                            
                        }
                    }

                    if (reloadNeeded) {
                        this.$store.commit('facilityStore/setSelectedFacilityIdList', { facilityIdList: selectedFacilityIds })
                        this.$store.commit('resultStore/setReloadNeeded', { reloadNeeded: true });
                    }
                }
            }
        }
    }
</script>
<style>

</style>