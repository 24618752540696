<template>
    <div>
        <v-dialog v-model="dialog" @keydown.esc="cancel">
            <v-card>
                <v-card-title>{{ selectedResult.EnvironmentalSourceName }} - {{ selectedResult.EnvironmentalSourceType }}  [{{ selectedResult.EnvironmentalSourceMRN }}]</v-card-title>
                <v-card-subtitle>{{ selectedResult.CollectionDateString }} {{ selectedResult.FacilityCd }}</v-card-subtitle>
                <v-container class="d-flex flex-column" fluid>
                    <v-simple-table fixed-header>
                        <template v-slot:default>
                            <thead>
                                <tr>
                                    <th class="text-left">Test</th>
                                    <th class="text-right">Result</th>
                                    <th class="text-right">Abnormal</th>
                                    <th class="text-right non-mobile-column">Reference</th>
                                    <th class="text-right non-mobile-column">Units</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="item in selectedResult.Results"
                                    :key="item.LabResultCdName">
                                    <td class="text-left">{{ item.ResultName }}</td>
                                    <td class="text-right">{{ item.ResultChar }}</td>
                                    <td class="text-right">{{ item.AbnormalFlag }}</td>
                                    <td class="text-right non-mobile-column">{{ item.RefRange }}</td>
                                    <td class="text-right non-mobile-column">{{ item.RefUnits }}</td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </v-container>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn v-if="canAcknowledgeCriticals" v-show="selectedResult.HasPanic" 
                           color="red darken-1"
                           text
                           @click="acknowledgeCritical">
                        Acknowledge
                    </v-btn>
                    <v-btn color="blue darken-1"
                           text
                           @click="dialog = false">
                        Back
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-alert prominent
                 v-show="noResults"
                 type="info">
            <v-row align="center">
                <v-col class="grow">
                    No results to show, would you like to load more?
                </v-col>
                <v-col class="shrink">
                    <v-btn @click="loadMoreResults">Load More</v-btn>
                </v-col>
            </v-row>
        </v-alert>
        <v-virtual-scroll :items="results"
                          :item-height="62"
                          class="my-list"
                          @scroll.native="scrolling">
            <template v-slot:default="{ item }">
                <v-list-item @click="showResults(item)">
                    <v-list-item-content>
                        <v-list-item-title>
                            <span style="color:firebrick; padding-right:25px" v-if="item.HasAbnormalResult">!</span>
                            <span v-bind:class="{ 'critical-panic-text': item.HasPanic }">{{ item.EnvironmentalSourceName }} - {{ item.EnvironmentalSourceType }}  [{{ item.EnvironmentalSourceMRN }}]</span></v-list-item-title>
                        <v-list-item-subtitle>{{ item.CollectionDateString }} - {{ item.FacilityCd }}</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
                <v-divider class="hr-divider"></v-divider>
            </template>
        </v-virtual-scroll>
    </div>
</template>

<script>
import resultsService from "@/services/resultsService";
import debounce from 'lodash.debounce';
import dayjs from 'dayjs';

function scrolling(event) {
    const element = event.currentTarget || event.target;
    
    if (element && element.scrollHeight - element.scrollTop < element.clientHeight + 600) {
        loadMoreData(this, this.$store.state.resultStore.currentEndDate);
    }
}

function filterResults(loadedData, onlyCriticals) {
    var currentResults = [];

    for (var i = 0; i < loadedData.length; i++) {
        if (!onlyCriticals || loadedData[i].HasAbnormalResult) {
             currentResults.push(loadedData[i]);
        }
    }

    return currentResults;
}

export default {
        name: "Results",  
        created: function () { 
            this.scrolling = debounce(this.scrolling, 10);
        },
        data: function () {
            var currentResults = filterResults(this.$store.state.resultStore.loadedData, this.$store.state.resultStore.onlyCriticals);
            return {
                dialog: false,
                noResults: false,
                scrolling,
                results: currentResults,
                selectedResult: {
                    CollectionDateString: '',
                    FacilityCd: ''
                }
            };
        },
        computed: {
          showNonCritical: function () {
              return !this.$store.state.resultStore.onlyCriticals;
          },
          reloadNeeded: function () {
              return this.$store.state.resultStore.reloadNeeded;
            },
          canAcknowledgeCriticals: function () {
              return this.$store.state.canAcknowledgeCriticals;
            }
        },
        watch: {
            showNonCritical: function () {                
              var store = this.$store.state.resultStore;
              this.results.length = 0;
              this.results.push(...filterResults(store.loadedData, store.onlyCriticals));
              this.checkResultArray();
            },
            reloadNeeded: function () {
                
                if (this.$store.state.resultStore.reloadNeeded) {
                    this.results.length = 0;

                    var currentThis = this;
                    var now = dayjs().add(1, 'day');
                    loadMoreData(currentThis, now);

                    currentThis.$store.commit('resultStore/setReloadNeeded', { loading: false });
                }
            }
      },
        methods: {            
          showResults(resultCollection) {
              this.selectedResult = resultCollection;
              this.dialog = true;
          },
          checkResultArray() {
              //this.noResults = this.results.length === 0;              
          },
          loadMoreResults() {
              this.noResults = false;
              loadMoreData(this, this.$store.state.resultStore.currentEndDate);
            },
            acknowledgeCritical() {
                var criticalIds = [];

                for (var i = 0; i < this.selectedResult.Results.length; i++) {
                    if (this.selectedResult.Results[i].LabResultCriticalPanicId !== null &&
                        this.selectedResult.Results[i].LabResultCriticalPanicId !== undefined) {
                        criticalIds.push(this.selectedResult.Results[i].LabResultCriticalPanicId);
                    }
                }

                resultsService.acknowledgeCriticals(criticalIds, this.selectedResult.EnvironmentalSourceMRN, this.selectedResult.FacilityCd)
                    .then(response => response.json())
                    .then(() => {
                        this.selectedResult.HasPanic = false;
                        this.dialog = false;
                    }).catch(() => {

                    });

            }
      },
      mounted: function () {
          if (this.$store.state.resultStore.currentEndDate === null) {
              var currentThis = this;
              var now = dayjs().add(1,'day');
              loadMoreData(currentThis, now);
          }
      }
};

    function loadMoreData(currentThis, startDate) {
        if (!currentThis.$store.state.resultStore.loading) {

            currentThis.$store.commit('resultStore/setLoading', { loading: true });
            var endDate = startDate.date(1).subtract(1, 'month');
            startDate = startDate.subtract(1, 'day');
            var onlyCritical = currentThis.$store.state.resultStore.onlyCriticals;

            resultsService
                .getResults(startDate, endDate, currentThis.$store.state.facilityStore.selectedFacilityIds)
                .then(response => response.json())
                .then(data => {
                    
                    for (var i = 0; i < data.length; i++) {
                        if (!onlyCritical || data[i].HasAbnormalResult) {
                            currentThis.results.push(data[i]);
                        }
                    }
                    //currentThis.checkResultArray();
                    currentThis.$store.commit('resultStore/saveLoadedData', { endDate, data });
                    currentThis.$store.commit('resultStore/setLoading', { loading: false });

                    if (currentThis.results.length < 14) {
                        var today = new dayjs();
                        if (today < endDate.add(1, 'year')) {
                            loadMoreData(currentThis, endDate);
                        }
                    }
                })
                .catch(() => {
                    currentThis.$router.push("/login");
                    currentThis.$store.commit('resultStore/setLoading', { loading: false });
                });
        }
    }    
</script>

<style scoped>
    .critical-panic-text {
        color: #d5152e
    }

    .hr-divider {
        width: calc(100% - 24px);
        margin-left: 12px;
    }

    .my-list {        
        height: calc(100vh - 90px);
        overflow-y: auto;
    }

    @media all and (min-width: 769px) {
        .test-name-column {
            width: 40%;
            text-align: left;
        }

        .mobile-column {
            width: 15%;
            text-align: right;
        }
    }

    @media all and (max-width: 768px) {
        .test-name-column {
            width: 60%;
            text-align: left;
        }

        .mobile-column {
            width: 20%;
            text-align: right;
        }

        .non-mobile-column {
            display: none;
            width: 0;
            height: 0;
            opacity: 0;
            visibility: collapse;
        }
    }
</style>